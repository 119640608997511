<template>
   <div class="container pb-5 mb-5">
         <SectionTitle title="Invitaciones a ofertar"/>
         <br>
         <div class="row">
            <div v-for="(oferta, index) in ofertas" :key="index" class="col-sm-4">
               <div class="card mb-4">
                  <div class="card-body">
                     <a :href="require('@/assets/ofertas/' + oferta.imagen)" data-fancybox="gallery">
                        <img class="mb-4 img-fluid text-center" style="height: 400px;overflow: hidden; width: 100%" :src="require('@/assets/ofertas/' + oferta.imagen)" alt="Oferta">
                     </a>
                  </div>
               </div>
            </div>               
         </div>
   </div>
</template>

<script>
export default {
    data(){
        return {
            ofertas: [
               { imagen: 'invitacionOfertasServicios2023.png', index: 1  },
               { imagen: 'invitacionOfertasSuministrosProductos2023.png', index: 1  },
            ]
        }
    }
};
</script>